
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Auth from "./Screens/Auth";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Auth />}>
          <Route index element={<Auth />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
