import React, { useState, useEffect } from "react";

function List_cars() {
  const [products, setProducts] = useState([]);
  const [message, setMessage] = useState("");
  const [update, setupdate] = useState(0);

  // Fetch products data from API when the component mounts
  useEffect(() => {
    fetch("https://data.aicorporationjp.com/getcarbyid.php")
      .then((response) => response.json())
      .then((data) => setProducts(data))
      .catch((error) => {
        console.error("Error fetching products:", error);
        setMessage("Error fetching products");
      });
  }, [update]);

  async function handleDelete(id) {
    const url = `https://data.aicorporationjp.com/delete_product.php?id=${id}`;

    try {
      const response = await fetch(url, {
        method: "DELETE",
      });
      if (!response.ok) {
        throw new Error("Failed to delete product");
      }
      const data = await response.json();
      setMessage(data.message);
      setupdate(update+1);
      // Handle success or update state as needed
    } catch (error) {
      console.error("Error removing product:", error);
      setMessage("Error removing product");
      // Handle error or display an error message
    }
  }

  return (
    <div className="flex flex-col ml-11 mr-11">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            <div className="flex items-center justify-center mt-11 mb-11">
              <table className="min-w-full text-left text-sm font-light">
                <thead className="border-b font-medium dark:border-neutral-500">
                <tr>
                    <th scope="col" className="ml-6">
                      ID
                    </th>
                    <th scope="col" className="ml-6">
                      Title
                    </th>
                    <th scope="col" className="ml-6">
                    Price
                    </th>
                    <th scope="col" className="ml-6">
                    phone
                    </th>
                    <th scope="col" className="ml-6">
                      Email
                    </th>
                    <th scope="col" className="ml-6">
                    Address
                    </th>
                    <th scope="col" className="ml-6">
                    Condition
                    </th>
                    <th scope="col" className="ml-6">
                    make
                    </th>
                    <th scope="col" className="ml-6">
                    model
                    </th>
                    <th scope="col" className="ml-6">
                    year
                    </th>
                    <th scope="col" className="ml-6">
                    engine
                    </th>
                    <th scope="col" className="ml-6">
                    hp
                    </th>
                    <th scope="col" className="ml-6">
                    drive
                    </th>
                    <th scope="col" className="ml-6">
                    description
                    </th>
                    <th scope="col" className="ml-6">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((product) => (
                    <tr 
                      key={product.id}
                      className="border-b dark:border-neutral-500"
                    >
                      <td className="whitespace-nowrap px-6 py-4">
                        {product.id}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4">
                        {product.title}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4">
                        ${product.price}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4">
                        {product.phone}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4">
                        {product.email}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4">
                        {product.address}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4">
                        {product.condition}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4">
                        {product.make}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4">
                        {product.model}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4">
                        {product.year}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4">
                        {product.engine}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4">
                        {product.hp}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4">
                        {product.drive}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4">
                        {product.desc}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4">
                        <button
                          className="text-success"
                          onClick={() => handleDelete(product.id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        
      </div>
      <div className="pt-11 mb-11 text-red-500 flex items-center items-align">
          {message}
        </div>
    </div>
  );
}

export default List_cars;
