import React, { useState, useEffect } from "react";
import logo from '../Assets/logo.png'
import Main from "./Main";

function Auth() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Initialize as false
  const [loginError, setLoginError] = useState("");

  const handleLogin = async () => {
    console.log(username, password);
    try {
      const response = await fetch(
        "https://data.aicorporationjp.com/get_login.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username, password }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data.isAuthenticated) {
          setIsAuthenticated(true); // Set isAuthenticated to true upon successful login
        } else {
          setLoginError("Invalid credentials. Please try again.");
        }
      } else {
        console.error("Failed to login:", response.status);
        setLoginError("Error during login. Please try again.");
      }
    } catch (error) {
      console.error("Error during login:", error);
      setLoginError("Error during login. Please try again.");
    }
  };

  if (isAuthenticated) {
    
    return (
      <div>
       <Main/>
      </div>
    );
  }

  return (
    <div className="pt-11 min-h-screen bg-gray-100 flex flex-col justify-center sm:py-12">
      <div className="p-10 xs:p-0 mx-auto md:w-full md:max-w-md">
        <img
          className="h-22"
          src={logo}
          alt="logo"
        />
        <h1 className="font-bold text-center text-2xl mb-5">Login</h1>
        <div className="bg-white shadow w-full rounded-lg divide-y divide-gray-200">
          <div className="px-5 py-7">
            <label className="font-semibold text-sm text-gray-600 pb-1 block">
              Username
            </label>
            <input
              type="text"
              className="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <label className="font-semibold text-sm text-gray-600 pb-1 block">
              Password
            </label>
            <input
              type="password"
              className="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              type="button"
              onClick={handleLogin} // Call handleLogin on button click
              className="transition duration-200 bg-orange-500 hover:bg-orange-600 focus:bg-orange-700 focus:shadow-sm focus:ring-4 focus:ring-orange-500 focus:ring-opacity-50 text-white w-full py-2.5 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block"
            >
              <span className="inline-block mr-2">Login</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-4 h-4 inline-block"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </button>
            {loginError && (
              <div className="text-red-500 mt-2">{loginError}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Auth;
