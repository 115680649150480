import React, { useState, useEffect } from "react";

function AddCars() {
    const [file, setFile] = useState(null);
const [imageurl, setImageUrl] = useState([]);

const handleFileChange = (e) => {
    setFile(e.target.files[0]);
};

function uploadFile() {
    const formData = new FormData();
    formData.append('file', file);

    fetch('https://data.aicorporationjp.com/upload.php', {
        method: 'POST',
        body: formData
    })
    .then(response => response.json())
    .then(result => {
        // Check if the response status is success and the URL is present
        if (result.status === "success" && result.url) {
            setImageUrl(prevUrls => [...prevUrls, result.url]);
            console.log(imageurl); // Move this line here to ensure it logs after state update
        } else {
            console.error('Error:', result.message); // Log the error message
        }
    })
    .catch(error => {
        console.error('Error:', error);
    });
}
    const [form, setForm] = useState({
        id: "",
        title: "",
        price: "",
        phone: "",
        email: "",
        address: "",
        condition: "",
        make: "",
        model: "",
        year: "",
        engine: "",
        hp: "",
        accer: "",
        range: "",
        drive: "",
        ts: "",
        image1: "",
        image2:"",
        image3: "",
        image4: "",
        image5: "",
        desc: ""
    });
    useEffect(() => {
        const updatedForm = {
            ...form
        };
        imageurl.forEach((url, index) => {
            updatedForm[`image${index + 1}`] = url;
        });
        setForm(updatedForm);
    }, [imageurl]);
    const [message, setMessage] = useState("");

    const handleInputChange = (fieldName, value) => {
        setForm({ ...form, [fieldName]: value });
    };
    const handleAddCar = async () => {
        // Validate form fields here if needed

        try {
            const bodyy = JSON.stringify(form);
            console.log("bodyy",bodyy);
            const response = await fetch("https://data.aicorporationjp.com/cp1.php", {
                method: "POST",
                body: bodyy,
                headers: {
                    "Content-Type": "application/json"
                },
                redirect: "follow"
            });

            if (response.ok) {
                const data = await response.json();
                setMessage(data.message);
                // Clear form fields on successful submission
                setForm({
                    id: "",
                    title: "",
                    price: "",
                    phone: "",
                    email: "",
                    address: "",
                    condition: "",
                    make: "",
                    model: "",
                    year: "",
                    engine: "",
                    hp: "",
                    accer: "",
                    range: "",
                    drive: "",
                    ts: "",
                    image: "",
                    image2: "",
                    image3: "",
                    image4: "",
                    image5: "",
                    desc: ""
                });
            } else {
                console.error("Failed to add car:", response.status);
                setMessage("Error adding car. Please try again.");
            }
        } catch (error) {
            console.error("Error adding car:", error);
            setMessage("Error adding car. Please try again.");
        }
    };

    return (
        <div className="flex items-center justify-center">
            <div className="items-center text-center">
                <h1 className="text-bold text-2xl text-center font-extrabold dark:text-white pt-14 bg-inherit">
                    Add Car Here
                </h1>
                {/* Input fields for each database field */}
                {/* Example: */}
                <div className="form-control w-full max-w-xs pt-11">
                    <label className="label">
                        <span className="label-text text-bold text-black text-xl">
                            Title:
                        </span>
                    </label>
                    <textarea
                        onChange={(e) => handleInputChange("title", e.target.value)}
                        value={form.title}
                        placeholder="Type here"
                        className="input input-bordered w-96 h-32 mb-4  resize-none"
                    ></textarea>
                </div>
                <div className="form-control w-full max-w-xs pt-11">
                    <label className="label">
                        <span className="label-text text-bold text-black text-xl">
                            price:
                        </span>
                    </label>
                    <input
                        onChange={(e) => handleInputChange("price", e.target.value)}
                        value={form.price}
                        type="number"
                        placeholder="Type here"
                        className="input input-bordered w-full max-w-xs"
                    />
                </div>
                <div className="form-control w-full max-w-xs pt-11">
                    <label className="label">
                        <span className="label-text text-bold text-black text-xl">
                            phone:
                        </span>
                    </label>
                    <input
                        onChange={(e) => handleInputChange("phone", e.target.value)}
                        value={form.phone}
                        type="number"
                        placeholder="Type here"
                        className="input input-bordered w-full max-w-xs"
                    />
                </div>
                <div className="form-control w-full max-w-xs pt-11">
                    <label className="label">
                        <span className="label-text text-bold text-black text-xl">
                            Email:
                        </span>
                    </label>
                    <input
                        onChange={(e) => handleInputChange("email", e.target.value)}
                        value={form.email}
                        type="text"
                        placeholder="Type here"
                        className="input input-bordered w-full max-w-xs"
                    />
                </div>
                <div className="form-control w-full max-w-xs pt-11">
                    <label className="label">
                        <span className="label-text text-bold text-black text-xl">
                            Address:
                        </span>
                    </label>
                    <input
                        onChange={(e) => handleInputChange("address", e.target.value)}
                        value={form.address}
                        type="text"
                        placeholder="Type here"
                        className="input input-bordered w-full max-w-xs"
                    />
                </div>
                <div className="form-control w-full max-w-xs pt-11">
                    <label className="label">
                        <span className="label-text text-bold text-black text-xl">
                            condition:
                        </span>
                    </label>
                    <input
                        onChange={(e) => handleInputChange("condition", e.target.value)}
                        value={form.condition}
                        type="text"
                        placeholder="Type here"
                        className="input input-bordered w-full max-w-xs"
                    />
                </div>
                <div className="form-control w-full max-w-xs pt-11">
                    <label className="label">
                        <span className="label-text text-bold text-black text-xl">
                            Make:
                        </span>
                    </label>
                    <input
                        onChange={(e) => handleInputChange("make", e.target.value)}
                        value={form.make}
                        type="text"
                        placeholder="Type here"
                        className="input input-bordered w-full max-w-xs"
                    />
                </div>
                <div className="form-control w-full max-w-xs pt-11">
                    <label className="label">
                        <span className="label-text text-bold text-black text-xl">
                            model:
                        </span>
                    </label>
                    <input
                        onChange={(e) => handleInputChange("model", e.target.value)}
                        value={form.model}
                        type="text"
                        placeholder="Type here"
                        className="input input-bordered w-full max-w-xs"
                    />
                </div>
                <div className="form-control w-full max-w-xs pt-11">
                    <label className="label">
                        <span className="label-text text-bold text-black text-xl">
                            Year:
                        </span>
                    </label>
                    <input
                        onChange={(e) => handleInputChange("year", e.target.value)}
                        value={form.year}
                        type="number"
                        placeholder="Type here"
                        className="input input-bordered w-full max-w-xs"
                    />
                </div>
                <div className="form-control w-full max-w-xs pt-11">
                    <label className="label">
                        <span className="label-text text-bold text-black text-xl">
                            Engine:
                        </span>
                    </label>
                    <input
                        onChange={(e) => handleInputChange("engine", e.target.value)}
                        value={form.engine}
                        type="text"
                        placeholder="Type here"
                        className="input input-bordered w-full max-w-xs"
                    />
                </div>
                <div className="form-control w-full max-w-xs pt-11">
                    <label className="label">
                        <span className="label-text text-bold text-black text-xl">
                            Horse Power:
                        </span>
                    </label>
                    <input
                        onChange={(e) => handleInputChange("hp", e.target.value)}
                        value={form.hp}
                        type="number"
                        placeholder="Type here"
                        className="input input-bordered w-full max-w-xs"
                    />
                </div>
                <div className="form-control w-full max-w-xs pt-11">
                    <label className="label">
                        <span className="label-text text-bold text-black text-xl">
                            Accerlation:
                        </span>
                    </label>
                    <input
                        onChange={(e) => handleInputChange("accer", e.target.value)}
                        value={form.accer}
                        type="text"
                        placeholder="Type here"
                        className="input input-bordered w-full max-w-xs"
                    />
                </div>
                <div className="form-control w-full max-w-xs pt-11">
                    <label className="label">
                        <span className="label-text text-bold text-black text-xl">
                            Range or Milage:
                        </span>
                    </label>
                    <input
                        onChange={(e) => handleInputChange("range", e.target.value)}
                        value={form.range}
                        type="text"
                        placeholder="Type here"
                        className="input input-bordered w-full max-w-xs"
                    />
                </div>
                <div className="form-control w-full max-w-xs pt-11">
                    <label className="label">
                        <span className="label-text text-bold text-black text-xl">
                            Drive:
                        </span>
                    </label>
                    <input
                        onChange={(e) => handleInputChange("drive", e.target.value)}
                        value={form.drive}
                        type="text"
                        placeholder="Type here"
                        className="input input-bordered w-full max-w-xs"
                    />
                </div>
                <div className="form-control w-full max-w-xs pt-11">
                    <label className="label">
                        <span className="label-text text-bold text-black text-xl">
                            Top Speed:
                        </span>
                    </label>
                    <input
                        onChange={(e) => handleInputChange("ts", e.target.value)}
                        value={form.ts}
                        type="number"
                        placeholder="Type here"
                        className="input input-bordered w-full max-w-xs"
                    />
                </div>
                <div className="form-control w-full max-w-xs pt-11">
                    <label className="label">
                        <span className="label-text text-bold text-black text-xl">
                            Image:
                        </span>
                    </label>
                    <input
                        onChange={(e) => handleFileChange(e)}
                        type="file"
                        accept="image/*"
                        className="input"
                    />
                    <button onClick={()=>{uploadFile()}} className="block mx-auto px-6 py-3 text-sm font-medium text-white rounded-lg bg-orange-500 border border-orange-500 hover:bg-orange-600 focus:ring-4 focus:outline-none focus:ring-orange-300 dark:bg-orange-700 dark:border-orange-700 dark:hover:bg-orange-800 dark:focus:ring-orange-800">
                        Upload
                    </button>
                    </div>
                    <div className="form-control w-full max-w-xs pt-11">
                    <label className="label">
                        <span className="label-text text-bold text-black text-xl">
                            Image2:
                        </span>
                    </label>
                    <input
                        onChange={(e) => handleFileChange(e)}
                        type="file"
                        accept="image/*"
                        className="input"
                    />
                    <button onClick={()=>{uploadFile()}} className="block mx-auto px-6 py-3 text-sm font-medium text-white rounded-lg bg-orange-500 border border-orange-500 hover:bg-orange-600 focus:ring-4 focus:outline-none focus:ring-orange-300 dark:bg-orange-700 dark:border-orange-700 dark:hover:bg-orange-800 dark:focus:ring-orange-800">
                        Upload
                    </button>
                    </div>
                    <div className="form-control w-full max-w-xs pt-11">
                    <label className="label">
                        <span className="label-text text-bold text-black text-xl">
                            Image3:
                        </span>
                    </label>
                    <input
                        onChange={(e) => handleFileChange(e)}
                        type="file"
                        accept="image/*"
                        className="input"
                    />
                    <button onClick={()=>{uploadFile()}} className="block mx-auto px-6 py-3 text-sm font-medium text-white rounded-lg bg-orange-500 border border-orange-500 hover:bg-orange-600 focus:ring-4 focus:outline-none focus:ring-orange-300 dark:bg-orange-700 dark:border-orange-700 dark:hover:bg-orange-800 dark:focus:ring-orange-800">
                        Upload
                    </button>
                    </div>
                    <div className="form-control w-full max-w-xs pt-11">
                    <label className="label">
                        <span className="label-text text-bold text-black text-xl">
                            Image4:
                        </span>
                    </label>
                    <input
                        onChange={(e) => handleFileChange(e)}
                        type="file"
                        accept="image/*"
                        className="input"
                    />
                    <button onClick={()=>{uploadFile()}} className="block mx-auto px-6 py-3 text-sm font-medium text-white rounded-lg bg-orange-500 border border-orange-500 hover:bg-orange-600 focus:ring-4 focus:outline-none focus:ring-orange-300 dark:bg-orange-700 dark:border-orange-700 dark:hover:bg-orange-800 dark:focus:ring-orange-800">
                        Upload
                    </button>
                    </div>
                    <div className="form-control w-full max-w-xs pt-11">
                    <label className="label">
                        <span className="label-text text-bold text-black text-xl">
                            Image5:
                        </span>
                    </label>
                    <input
                        onChange={(e) => handleFileChange(e)}
                        type="file"
                        accept="image/*"
                        className="input"
                    />
                    <button onClick={()=>{uploadFile()}} className="block mx-auto px-6 py-3 text-sm font-medium text-white rounded-lg bg-orange-500 border border-orange-500 hover:bg-orange-600 focus:ring-4 focus:outline-none focus:ring-orange-300 dark:bg-orange-700 dark:border-orange-700 dark:hover:bg-orange-800 dark:focus:ring-orange-800">
                        Upload
                    </button>
                    </div>
                    <div className="form-control w-full max-w-xs pt-11">
                        <label className="label">
                            <span className="label-text text-bold text-black text-xl">
                                description:
                            </span>
                        </label>
                        <input
                            onChange={(e) => handleInputChange("desc", e.target.value)}
                            value={form.desc}
                            placeholder="Type here"
                            className="input input-bordered w-96 h-32 mb-4  resize-none"
                        ></input>
                    </div>

                    {/* Repeat similar blocks for other fields */}
                    {/* Submit button */}
                    <div className="pt-11">
                        <button
                            onClick={handleAddCar}
                            className="block mx-auto px-6 py-3 text-sm font-medium text-white rounded-lg bg-orange-500 border border-orange-500 hover:bg-orange-600 focus:ring-4 focus:outline-none focus:ring-orange-300 dark:bg-orange-700 dark:border-orange-700 dark:hover:bg-orange-800 dark:focus:ring-orange-800"
                        >
                            Submit
                        </button>
                    </div>
                    {/* Display error or success message */}
                    <div className="pt-11 mb-11 text-red-500">{message}</div>
                </div>
            </div>
    );
}

export default AddCars;
