/* eslint-disable react/jsx-pascal-case */
import React, { useState } from "react";
import Add_Car from "../Manage/Add_Car";
import List_cars from "../Manage/List_cars";

function Main() {
  const [activeComponent, setActiveComponent] = useState(null);
  const renderComponent = () => {
    switch (activeComponent) {
      case "addProducts":
        return <Add_Car/>;
      case "ListProducts":
        return <List_cars />;
      default:
        return null;
    }
    
  };
  return (
    <>
      <h1 className="items-center text-center text-4xl font-extrabold dark:text-white pt-14 bg-inherit">
        ADMIN PANEL
      </h1>
      <div className="flex items-center justify-center pt-11">
        
          <button
            onClick={() => setActiveComponent("addProducts")}
            className="btn mr-3 text-black rounded-lg bg-orange-200 border border-orange-200 hover:bg-orange-300 focus:ring-4 focus:outline-none focus:ring-orange-300 dark:bg-orange-300 dark:border-orange-400 dark:hover:bg-orange-300 dark:focus:ring-orange-300"
          >
            Add Cars
          </button>
          <button
            onClick={() => setActiveComponent("ListProducts")}
            className="btn mr-3 text-black rounded-lg bg-orange-200 border border-orange-200 hover:bg-orange-300 focus:ring-4 focus:outline-none focus:ring-orange-300 dark:bg-orange-300 dark:border-orange-400 dark:hover:bg-orange-300 dark:focus:ring-orange-300"
          >
            List Cars
          </button>
        </div>
      <div className="mt-11"></div>
      <div>{renderComponent()}</div>
    </>
  );
}

export default Main;
